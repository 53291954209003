
import { type LibsClickToPay, type MastercardCheckoutServicesMethods } from '../types'
import { getCookie, setCookie, removeCookie } from '@shein/common-function'
import { useAppConfigs } from '@shein-aidc/bs-sdk-libs-manager'
import { DEFAULT_LOCALE, ALL_LOCALES } from './locales'
import { transformCardData, formatCheckoutResponse, generateUUIDv4 } from './utils'
import { PROD_SRC_DPA_ID, SANDBOX_SRC_DPA_ID } from './constant'

interface C2pApiResponse<T = Record<string, any>> {
  status: 'success' | 'error' | 'fail' | 'cancel';
  message: string;
  info: T;
  errorDetails?: Record<string, any> | null;
  extraData?: Record<string, any> | null;
}

interface InitRequestParameters {
  srcDpaId?: LibsClickToPay.InitRequestParameters['srcDpaId']; // DPA ID
  cardBrands?: Array<LibsClickToPay.CardBrands>; // 卡组，不传则默认全部
  amount: LibsClickToPay.TransactionAmount['transactionAmount']; // 交易金额
  currencyCode: LibsClickToPay.TransactionAmount['transactionCurrencyCode']; // 交易币种
  dpaName?: LibsClickToPay.DpaData['dpaName']; // DPA名称, 默认 SHEIN
  dpaPresentationName?: LibsClickToPay.DpaData['dpaPresentationName']; // DPA展示名称, 默认 SHEIN
  dpaLocale?: LibsClickToPay.DpaTransactionOptions['dpaLocale']; // DPA语言, 默认 en_US
}

interface FormatCheckoutResponseParameters {
  status: 'success' | 'fail' | 'cancel';
  data?: {
    srcFlowId: string; // 支付入参
    correlationId: string; // 支付入参
    merchantTransactionId: string; // 支付入参
    tokenList: ReturnType<typeof transformCardData>; // 卡列表
  };
  errorDetails?: {
    status: string | number; // 错误状态
    reason: string; // 错误原因
    message: string; // 错误信息
  },
  fail_tips?: string; // 失败提示
  // mock
  originData?: LibsClickToPay.CheckoutResponseParameters;
}

const DEFAULT_INIT_PARAMETERS: Pick<LibsClickToPay.InitRequestParameters, 'srcDpaId' | 'cardBrands' | 'dpaData' | 'checkoutExperience' | 'dpaTransactionOptions' | 'recognitionToken'> = {
  srcDpaId: '',
  cardBrands: [
    'mastercard',
    'visa',
    'amex',
    'discover',
  ],
  dpaData: {
    dpaName: 'SHEIN',
    dpaPresentationName: 'SHEIN',
  },
  dpaTransactionOptions: {
    dpaAcceptedBillingCountries: [],
    dpaAcceptedShippingCountries: [],
    dpaBillingPreference: 'NONE',
    dpaShippingPreference: 'NONE',
    dpaLocale: DEFAULT_LOCALE,
    consumerNameRequested: false,
    consumerEmailAddressRequested: false,
    consumerPhoneNumberRequested: false,
    threeDsPreference: 'NONE',
    paymentOptions: [{
      dpaDynamicDataTtlMinutes: 15,
      dynamicDataType: 'NONE',
    }],
    transactionAmount: {
      transactionAmount: 0,
      transactionCurrencyCode: 'USD',
    },
    confirmPayment: false,
  },
  checkoutExperience: 'WITHIN_CHECKOUT',
}

// recognition token 有效期 180 天
const RECOGNITION_TOKEN_EXPIRES = 180 * 24 * 60 * 60

export const RECOGNITION_TOKEN_KEY = 'recognitionToken'

export const DEFAULT_CARD_BRANDS: Array<LibsClickToPay.CardBrands> = [
  'mastercard',
  'visa',
  'discover',
  'amex',
]

/**
 * C2P 支付服务
 *
 * @export
 * @class C2pCheckoutService
 */
export class C2pCheckoutService {

  c2pCheckoutInstance: MastercardCheckoutServicesMethods

  appConfigs: ReturnType<typeof useAppConfigs>

  traceId: string

  initParams

  constructor() {
    this.traceId = generateUUIDv4()
    this.c2pCheckoutInstance = null as any
    this.appConfigs = useAppConfigs()
    this.initParams = this.getDefaulParams()
  }

  get isSandBox() {
    return ['localhost', 'debug'].includes(this.appConfigs?.$envs?.NODE_SERVER_ENV)
  }

  private getDefaulParams = () => ({ ...DEFAULT_INIT_PARAMETERS })

  private commonResponse = (response?: Partial<C2pApiResponse<any>>): C2pApiResponse<any> => {
    return {
      status: response?.status || 'error',
      message: response?.message || '',
      info: response?.info || null,
      errorDetails: response?.errorDetails || null,
    }
  }

  private formatC2pError = (error: any): {
    name?: string;
    reason?: string;
    details?: Record<string, any>;
    message?: string;
  } => {
    try {
      return {
        name: error?.name || 'C2PError',
        reason: error?.reason,
        details: error?.details,
        message: error?.message,
      }
    } catch (e) {
      return {}
    }
  }

  private debuggerLog(...args) {
    if (typeof window === 'undefined') return
    try {
      const enableDebugLog = sessionStorage?.getItem?.('__bs__enable_debug_log')
      if (!enableDebugLog) return
      console.info(
        '%cC2P Pay Libs Debug Info:',
        'background:#35495e ; padding: 3px 3px; border-radius: 3px;  color: #fff',
        ...args,
        '\n',
      )
    } catch (e) {
      console.log(...args)
    }
  }

  private _checkInit = () => {
    if (typeof window === 'undefined' || typeof MastercardCheckoutServices === 'undefined') {
      throw new Error('MastercardCheckoutServices is not defined')
    }
    return !!this.c2pCheckoutInstance
  }

  /**
   * 初始化 C2P SDK
   *
   * 初始化过程中会实例化 MastercardCheckoutServices，并根据环境设置相关参数，
   * 最终调用 SDK 的 init 方法进行配置。
   *
   * @param parameters - 初始化参数，包含交易金额、币种、卡组及 DPA 信息等
   * @returns Promise<C2pApiResponse<LibsClickToPay.InitResponseParameters>>
   *   返回一个 Promise 对象，resolve 时包含操作状态、提示信息及初始化返回数据
   *
   * @example
   * ```typescript
   * const response = await c2pCheckoutService.init({
   *   amount: 100,
   *   currencyCode: 'USD',
   *   dpaLocale: 'en_US',
   *   cardBrands: ['mastercard', 'visa']
   * });
   * if (response.status === 'success') {
   *   console.log('初始化成功', response.info);
   * } else {
   *   console.error('初始化失败', response.message);
   * }
   * ```
  */
  init = async (parameters: Omit<InitRequestParameters, 'srcDpaId'>): Promise<C2pApiResponse<LibsClickToPay.InitResponseParameters>> => {
    try {
      if (typeof window === 'undefined') return this.commonResponse()
      if (typeof MastercardCheckoutServices === 'undefined') return this.commonResponse({ status: 'error', message: 'MastercardCheckoutServices is not defined' })
        this.c2pCheckoutInstance = new MastercardCheckoutServices()
        // @ts-ignore
        window.__c2pCheckoutService__ = this.c2pCheckoutInstance

      const recognitionToken = getCookie(RECOGNITION_TOKEN_KEY) || ''

      const isSandBox = ['localhost', 'debug'].includes(this.appConfigs?.$envs?.NODE_SERVER_ENV)

      const initParameters = {
        ...this.initParams,
        srcDpaId: isSandBox ? SANDBOX_SRC_DPA_ID : PROD_SRC_DPA_ID,
        cardBrands: DEFAULT_CARD_BRANDS,
      }

      if (recognitionToken) {
        initParameters.recognitionToken = recognitionToken
      }
      // 语种
      if (parameters.dpaLocale && ALL_LOCALES.includes(parameters.dpaLocale) && initParameters.dpaTransactionOptions) {
        initParameters.dpaTransactionOptions.dpaLocale = parameters.dpaLocale
      }

      if (parameters.amount && parameters.currencyCode && initParameters.dpaTransactionOptions) {
        initParameters.dpaTransactionOptions.transactionAmount = {
          transactionAmount: parameters.amount,
          transactionCurrencyCode: parameters.currencyCode,
        }
      }

      if (Array.isArray(parameters.cardBrands)) {
        initParameters.cardBrands = parameters.cardBrands
      }

      if (parameters.dpaName) {
        initParameters.dpaData.dpaName = parameters.dpaName
      }

      if (parameters.dpaPresentationName) {
        initParameters.dpaData.dpaPresentationName = parameters.dpaPresentationName
      }

      this.initParams = initParameters

      this.debuggerLog('init==parameters===', initParameters)
      const response = await this.c2pCheckoutInstance.init(initParameters)
      this.debuggerLog('init==response===', response)

      return {
        status: 'success',
        message: 'init success',
        info: response,
        extraData: {
          parameters: initParameters,
        },
      }
    } catch (error: any) {
      const errInfo = this.formatC2pError(error)
      this.debuggerLog('init==error===', errInfo)
      return this.commonResponse({ status: 'error', message: errInfo?.message || errInfo?.reason, errorDetails: errInfo })
    }
  }

  /**
   * 获取token列表
   *
   * 调用 SDK 的 getCards 方法获取当前保存的卡片数据，并对数据进行格式转换处理。
   *
   * @returns Promise<C2pApiResponse<LibsClickToPay.GetCardsResponseParameters>>
   *   返回一个 Promise 对象，resolve 时返回操作状态、提示信息和格式化后的卡片数据
   *
   * @example
   * ```typescript
   * const cardsResponse = await c2pCheckoutService.getCards();
   * if (cardsResponse.status === 'success') {
   *   console.log('卡片列表', cardsResponse.info);
   * } else {
   *   console.error('获取卡片失败', cardsResponse.message);
   * }
   * ```
   */
  getCards = async (): Promise<C2pApiResponse<LibsClickToPay.GetCardsResponseParameters>> => {
    try {
      if (!this._checkInit()) return this.commonResponse({ status: 'error', message: 'C2P SDK is not initialized' })

      const response = await this.c2pCheckoutInstance.getCards()
      this.debuggerLog('getCards==response===', response)
      return {
        status: 'success',
        message: 'get cards success',
        info: transformCardData(response as LibsClickToPay.MaskedCard[]),
      }
    } catch (error: any) {
      const errInfo = this.formatC2pError(error)
      this.debuggerLog('getCards==error===', errInfo)
      return this.commonResponse({ status: 'error', message: errInfo?.message || errInfo?.reason, errorDetails: errInfo, info: [] })
    }
  }

  /**
   * 验证是否存在远程 token
   *
   * 此方法用于通过邮箱或手机号码以及区号来检测用户是否已经存在远程 token。若 email 存在，则优先使用 email；若 email 不存在，
   * 则需要同时提供 phone 和 area_code，用于构造 mobileNumber 参数。调用成功后返回的响应对象中包含 consumerPresent 字段，
   * 表示消费者是否已存在。
   *
   * @param parameters - 一个对象，包含以下可选属性：
   *   - email: 用户的邮箱地址。如果存在则优先使用此属性。
   *   - phone: 用户的手机号码。当 email 不提供时，此属性与 area_code 构成手机号码信息。
   *   - area_code: 手机号码的国家码。当 email 不提供时，此属性与 phone 构成手机号码信息。
   *
   * @returns Promise<C2pApiResponse<LibsClickToPay.IdLookupResponseParameters>>
   *   一个 Promise 对象，resolve 时返回一个响应对象，该对象包含：
   *     - status: 操作状态（'success'、'error'、'fail'、'cancel'）。
   *     - message: 描述信息或错误说明。
   *     - info: 包含 consumerPresent 字段，指示是否存在远程 token 的布尔值。
   *
   * @example
   * ```typescript
   * const response = await c2pCheckoutService.idLookup({
   *   email: 'user@example.com'
   * });
   *
   * // 或者使用手机号码和国家码
   * const response = await c2pCheckoutService.idLookup({
   *   phone: '1234567890',
   *   area_code: '1'
   * });
   *
   * if (response.status === 'success') {
   *   console.log('消费者存在：', response.info.consumerPresent);
   * } else {
   *   console.error('ID 查找失败：', response.message);
   * }
   * ```
  */
  idLookup = async (parameters: {
    email?: string;
    phone?: LibsClickToPay.PhoneNumber['phoneNumber'];
    area_code?: LibsClickToPay.PhoneNumber['countryCode'];
  }): Promise<C2pApiResponse<LibsClickToPay.IdLookupResponseParameters>> => {
    try {
      if (!this._checkInit()) return this.commonResponse({ status: 'error', message: 'C2P SDK is not initialized' })

      const params = {} as LibsClickToPay.IdLookupRequestParameters
      if (parameters.email) {
        params.email = parameters.email
      } else {
        if (parameters.phone && parameters.area_code) {
          params.mobileNumber = {
            countryCode: parameters.area_code,
            phoneNumber: parameters.phone,
          }
        } else {
          return this.commonResponse({ status: 'error', message: 'email or phone is required' })
        }
      }

      this.debuggerLog('idLookup==parameters===', { parameters, params })

      const response = await this.c2pCheckoutInstance.idLookup(params)
      this.debuggerLog('idLookup==response===', response)
      return {
        status: 'success',
        message: 'id lookup success',
        info: { consumerPresent: !!response.consumerPresent },
      }
    } catch (error: any) {
      const errInfo = this.formatC2pError(error)
      this.debuggerLog('idLookup==error===', errInfo)
      return this.commonResponse({ status: 'error', message: errInfo?.message || errInfo?.reason, errorDetails: errInfo })
    }
  }

  /**
   * 发起验证请求
   *
   * 此方法用于调用 C2P SDK 的 initiateValidation 接口，对用户信息进行验证。该方法接收一个参数对象，
   * 该对象需符合 LibsClickToPay.InitiateValidationRequestParameters 类型的定义，并返回封装后的验证响应数据。
   *
   * @param parameters - 验证请求参数，包含必要的验证信息，如用户标识、验证码等（依据实际接口要求）。
   * @returns Promise<C2pApiResponse<LibsClickToPay.InitiateValidationResponseParameters>>
   *   一个 Promise 对象，resolve 时返回的对象包含以下字段：
   *     - status: 接口调用状态（'success'、'error'、'fail'、'cancel'）。
   *     - message: 操作结果说明或错误信息。
   *     - info: 业务数据，具体结构依赖于 C2P SDK 返回的数据。
   *
   * @example
   * ```typescript
   * const response = await c2pCheckoutService.initiateValidation({
   *   email: 'user@example.com',
   *   // 其他必要参数…
   * });
   * if (response.status === 'success') {
   *   console.log('验证请求成功', response.info);
   * } else {
   *   console.error('验证请求失败', response.message);
   * }
   * ```
  */
  initiateValidation = async (parameters: LibsClickToPay.InitiateValidationRequestParameters): Promise<C2pApiResponse<LibsClickToPay.InitiateValidationResponseParameters>> => {
    try {
      if (!this._checkInit()) return this.commonResponse({ status: 'error', message: 'C2P SDK is not initialized' })
      this.debuggerLog('initiateValidation==parameters===', parameters)
      const response = await this.c2pCheckoutInstance.initiateValidation(parameters)
      this.debuggerLog('initiateValidation==response===', response)
      return {
        status: 'success',
        message: 'initiate validation success',
        info: response,
      }
    } catch (error: any) {
      const errInfo = this.formatC2pError(error)
      this.debuggerLog('initiateValidation==error===', errInfo)
      return this.commonResponse({ status: 'error', message: errInfo?.message || errInfo?.reason, errorDetails: errInfo })
    }
  }
  /**
   * 验证用户信息
   *
   * 该方法使用 C2P SDK 的 validate 接口对用户提交的数据进行验证，
   * 并返回格式化后的卡片信息。
   *
   * @param parameters - 验证所需参数，符合 LibsClickToPay.ValidateRequestParameters 类型
   *
   * @returns Promise<C2pApiResponse<LibsClickToPay.ValidateResponseParameters>>
   *   返回一个 Promise 对象，其中包含操作状态、提示信息及验证返回的数据（经过格式转换）
   *
   * @example
   * ```typescript
   * const response = await c2pCheckoutService.validate({
   *   // 填写验证参数
   * });
   * ```
   */
  validate = async (parameters: LibsClickToPay.ValidateRequestParameters): Promise<C2pApiResponse<LibsClickToPay.ValidateResponseParameters>> => {
    try {
      if (!this._checkInit()) return this.commonResponse({ status: 'error', message: 'C2P SDK is not initialized' })
      this.debuggerLog('validate==parameters===', parameters)
      const response = await this.c2pCheckoutInstance.validate(parameters)
      this.debuggerLog('validate==response===', response)
      return {
        status: 'success',
        message: 'validate success',
        info: transformCardData(response as LibsClickToPay.MaskedCard[]),
      }
    } catch (error: any) {
      const errInfo = this.formatC2pError(error)
      this.debuggerLog('validate==error===', errInfo)
      return this.commonResponse({ status: 'error', message: errInfo?.message || errInfo?.reason, errorDetails: errInfo, info: [] })
    }
  }

  /**
   * 加密卡片信息
   *
   * 通过调用 C2P SDK 的 encryptCard 接口，对用户卡片数据进行加密，
   * 并返回加密后数据。
   *
   * @param parameters - 加密所需参数，符合 LibsClickToPay.EncryptCardRequestParameters 类型
   *
   * @returns Promise<C2pApiResponse<LibsClickToPay.EncryptCardResponseParameters>>
   *   返回一个 Promise 对象，resolve 后包含操作状态、提示信息及加密结果信息
   *
   * @example
   * ```typescript
   * const response = await c2pCheckoutService.encryptCard({
   *   cardNumber: '4111111111111111',
   *   expiryDate: '12/25',
   *   cvv: '123'
   * });
   * ```
   */
  encryptCard = async (parameters: LibsClickToPay.EncryptCardRequestParameters): Promise<C2pApiResponse<LibsClickToPay.EncryptCardResponseParameters>> => {
    try {
      if (!this._checkInit()) return this.commonResponse({ status: 'error', message: 'C2P SDK is not initialized' })
      this.debuggerLog('encryptCard==parameters===', parameters)
      const response = await this.c2pCheckoutInstance.encryptCard(parameters)
      this.debuggerLog('encryptCard==response===', response)
      return {
        status: 'success',
        message: 'encrypt card success',
        info: response,
      }
    } catch (error: any) {
      const errInfo = this.formatC2pError(error)
      this.debuggerLog('encryptCard==error===', errInfo)
      return this.commonResponse({ status: 'error', message: errInfo?.message || errInfo?.reason, errorDetails: errInfo })
    }
  }

  /**
   * 使用新卡完成支付
   *
   * 该方法用于使用新录入的加密卡信息进行支付，
   * 支持传入额外的用户信息以及支付金额和币种，调用成功后返回支付数据。
   *
   * @param parameters - 对象类型参数，包含：
   *   - encryptedCard: 加密的卡片数据
   *   - cardBrand: 卡片品牌
   *   - windowRef: 当前窗口引用
   *   - rememberMe: 是否记住用户信息
   *   - recognitionTokenRequested: 是否请求 recognitionToken（可选）
   *   - email: 用户邮箱（可选）
   *   - phone: 用户手机号码（可选）
   *   - area_code: 手机号码国家码（可选）
   *   - amount: 交易金额（可选）
   *   - currency: 币种（可选）
   *
   * @returns Promise<C2pApiResponse<Pick<FormatCheckoutResponseParameters, 'data' | 'originData'>>>
   *   返回一个 Promise 对象，其中包含支付结果及原始支付返回数据
   *
   * @example
   * ```typescript
   * const checkoutResponse = await c2pCheckoutService.checkoutWithNewCard({
   *   encryptedCard: 'encrypted-card-data',
   *   cardBrand: 'visa',
   *   windowRef: window,
   *   rememberMe: true,
   *   email: 'user@example.com',
   *   amount: 100,
   *   currency: 'USD'
   * });
   * ```
   */
  checkoutWithNewCard = async (parameters: {
    encryptedCard: LibsClickToPay.CheckoutWithNewCardRequestParameters['encryptedCard'];
    cardBrand: LibsClickToPay.CheckoutWithNewCardRequestParameters['cardBrand'];
    windowRef: LibsClickToPay.CheckoutWithNewCardRequestParameters['windowRef'];
    rememberMe: LibsClickToPay.CheckoutWithNewCardRequestParameters['rememberMe'];
    recognitionTokenRequested?: boolean;
    email?: string;
    phone?: LibsClickToPay.PhoneNumber['phoneNumber'];
    area_code?: LibsClickToPay.PhoneNumber['countryCode'];
    amount?: LibsClickToPay.TransactionAmount['transactionAmount'];
    currency?: LibsClickToPay.TransactionAmount['transactionCurrencyCode'];
  }): Promise<C2pApiResponse<Pick<FormatCheckoutResponseParameters, 'data' | 'originData'>>> => {
    try {
      if (!this._checkInit()) return this.commonResponse({ status: 'error', message: 'C2P SDK is not initialized' })

      const complianceSettings = {
        'privacy': {
          'acceptedVersion': 'LATEST',
          'latestVersion': 'LATEST',
          'latestVersionUri': 'https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html',
        },
        'tnc': {
          'acceptedVersion': 'LATEST',
          'latestVersion': 'LATEST',
          'latestVersionUri': 'https://www.mastercard.com/global/click-to-pay/country-listing/terms.html',
        },
        'cookie': {
          'acceptedVersion': 'LATEST',
          'latestVersion': 'LATEST',
          'latestVersionUri': 'https://www.mastercard.com/global/click-to-pay/en-us/privacy-notice.html',
        },
      }
      const params = {
        encryptedCard: parameters.encryptedCard || '',
        cardBrand: parameters.cardBrand || '',
        windowRef: parameters.windowRef,
        rememberMe: parameters.rememberMe,
        recognitionTokenRequested: parameters.recognitionTokenRequested,
        checkoutExperience: this.initParams.checkoutExperience,
        dpaTransactionOptions: Object.assign({}, { paymentOptions: this.initParams.dpaTransactionOptions?.paymentOptions }),
        complianceSettings,
      } as LibsClickToPay.CheckoutWithNewCardRequestParameters

      // 25/03/10 根据卡种来设置 dynamicDataType
      // const noneDynamicDataTypeCardBrands = ['amex', 'discover', 'visa']
      // const baseDynamicDataTypeCardBrands = ['mastercard']
      // if (params.dpaTransactionOptions?.paymentOptions) {
      //   if (noneDynamicDataTypeCardBrands.includes(params.cardBrand)) {
      //     params.dpaTransactionOptions.paymentOptions = [{
      //       dpaDynamicDataTtlMinutes: 15,
      //       dynamicDataType: 'NONE',
      //     }]
      //   }
      //   if (baseDynamicDataTypeCardBrands.includes(params.cardBrand)) {
      //     params.dpaTransactionOptions.paymentOptions = this.initParams.dpaTransactionOptions?.paymentOptions
      //   }
      // }

      if (parameters.email) {
        if (!params.consumer) params.consumer = {} as LibsClickToPay.Consumer
        params.consumer.emailAddress = parameters.email
      }

      if (parameters.phone && parameters.area_code) {
        if (!params.consumer) params.consumer = {} as LibsClickToPay.Consumer
        params.consumer.mobileNumber = {
          countryCode: parameters.area_code,
          phoneNumber: parameters.phone,
        }
      }

      // if (params.rememberMe && params?.consumer?.emailAddress && params?.consumer?.mobileNumber) {
      //   params.complianceSettings = complianceSettings
      // }

      if (parameters.amount != null && parameters.currency && params.dpaTransactionOptions) {
        params.dpaTransactionOptions.transactionAmount = {
          transactionAmount: parameters.amount,
          transactionCurrencyCode: parameters.currency,
        }
      }

      this.debuggerLog('checkoutWithNewCard==parameters===', { parameters, params })
      const response = await this.c2pCheckoutInstance.checkoutWithNewCard(params)
      const { status, data, originData, fail_tips } = formatCheckoutResponse(response) as FormatCheckoutResponseParameters
      this.debuggerLog('checkoutWithNewCard==response===', response)
      if (params.recognitionTokenRequested && response?.recognitionToken) {
        setCookie({ key: RECOGNITION_TOKEN_KEY, value: response.recognitionToken, end: RECOGNITION_TOKEN_EXPIRES })
        this.debuggerLog('checkoutWithNewCard==recognitionToken==setCookie===', response.recognitionToken)
      }
      return {
        status,
        message: fail_tips || 'checkout with new card success',
        info: {
          data,
          originData,
        },
      }
    } catch (error: any) {
      const errInfo = this.formatC2pError(error)
      this.debuggerLog('checkoutWithNewCard==error===', errInfo)
      return this.commonResponse({ status: 'error', message: errInfo?.message || errInfo?.reason, errorDetails: errInfo, info: { data: {} } })
    }
  }
  /**
   * 使用已保存卡信息完成支付
   *
   * 该方法用于通过已存储的数字卡 ID 发起支付请求，
   * 并返回支付处理结果及原始返回数据。
   *
   * @param parameters - 包含以下属性：
   *   - srcDigitalCardId: 保存卡的数字 ID
   *   - windowRef: 当前窗口引用
   *   - rememberMe: 是否记住用户状态
   *   - recognitionTokenRequested: 是否请求 recognitionToken
   *   - amount: 交易金额
   *   - currency: 币种
   *
   * @returns Promise<C2pApiResponse<Pick<FormatCheckoutResponseParameters, 'data' | 'originData'>>>
   *   返回一个 Promise 对象，resolve 后包含支付状态、提示及返回支付数据
   *
   * @example
   * ```typescript
   * const response = await c2pCheckoutService.checkoutWithCard({
   *   srcDigitalCardId: 'saved-card-id',
   *   windowRef: window,
   *   rememberMe: true,
   *   recognitionTokenRequested: true,
   *   amount: 100,
   *   currency: 'USD'
   * });
   * ```
   */
  checkoutWithCard = async (parameters: {
    srcDigitalCardId: LibsClickToPay.CheckoutWithCardRequestParameters['srcDigitalCardId'];
    windowRef: LibsClickToPay.CheckoutWithCardRequestParameters['windowRef'];
    rememberMe: LibsClickToPay.CheckoutWithCardRequestParameters['rememberMe'];
    recognitionTokenRequested: boolean;
    amount: LibsClickToPay.TransactionAmount['transactionAmount'];
    currency: LibsClickToPay.TransactionAmount['transactionCurrencyCode'];
  }): Promise<C2pApiResponse<Pick<FormatCheckoutResponseParameters, 'data' | 'originData'>>> => {
    try {
      if (!this._checkInit()) return this.commonResponse({ status: 'error', message: 'C2P SDK is not initialized' })
      const params = {
        srcDigitalCardId: parameters.srcDigitalCardId,
        windowRef: parameters.windowRef,
        rememberMe: parameters.rememberMe,
        recognitionTokenRequested: parameters.recognitionTokenRequested,
        checkoutExperience: this.initParams.checkoutExperience,
        dpaTransactionOptions: Object.assign({}, { paymentOptions: this.initParams.dpaTransactionOptions?.paymentOptions }),
      } as LibsClickToPay.CheckoutWithCardRequestParameters

      if (!parameters.amount != null && parameters.currency && params.dpaTransactionOptions) {
        params.dpaTransactionOptions.transactionAmount = {
          transactionAmount: parameters.amount,
          transactionCurrencyCode: parameters.currency,
        }
      }

      this.debuggerLog('checkoutWithCard==parameters===', { parameters, params })
      const response = await this.c2pCheckoutInstance.checkoutWithCard(params)
      const { status, data, originData, fail_tips } = formatCheckoutResponse(response) as FormatCheckoutResponseParameters
      this.debuggerLog('checkoutWithCard==response===', response)
      if (params.recognitionTokenRequested && response?.recognitionToken) {
        setCookie({ key: RECOGNITION_TOKEN_KEY, value: response.recognitionToken, end: RECOGNITION_TOKEN_EXPIRES })
        this.debuggerLog('checkoutWithCard==recognitionToken==setCookie===', response.recognitionToken)
      }
      return {
        status,
        message: fail_tips || 'checkout with card success',
        info: {
          data,
          originData,
        },
      }
    } catch (error: any) {
      const errInfo = this.formatC2pError(error)
      this.debuggerLog('checkoutWithCard==error===', errInfo)
      return this.commonResponse({ status: 'error', message: errInfo?.message || errInfo?.reason, errorDetails: errInfo, info: { data: {} } })
    }
  }

  signOut = async (): Promise<C2pApiResponse> => {
    try {
      if (!this._checkInit()) return this.commonResponse({ status: 'error', message: 'C2P SDK is not initialized' })
      const recognitionToken = getCookie(RECOGNITION_TOKEN_KEY) || ''
      const response = await this.c2pCheckoutInstance.signOut(recognitionToken ? { recognitionToken } : undefined)
      this.debuggerLog('signOut==response===', response, { recognitionToken })
      // recognized 为 false 时表示解绑成功！！！！
      const isSignOutError = response.recognized
      if (!response.recognized) {
        removeCookie({ key: RECOGNITION_TOKEN_KEY })
      }
      this.c2pCheckoutInstance = null as any
      return {
        status: 'success',
        message: isSignOutError ? 'sign out errors occurred' : 'sign out success',
        info: {
          unbindSucccess: !isSignOutError,
          tokenList: transformCardData(response?.cards as LibsClickToPay.MaskedCard[]) || [],
          originData: response,
        },
      }
    } catch (error: any) {
      const errInfo = this.formatC2pError(error)
      this.debuggerLog('signOut==error===', errInfo)
      return this.commonResponse({ status: 'error', message: errInfo?.message || errInfo?.reason, errorDetails: errInfo })
    }
  }
}
